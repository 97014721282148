import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-unificator/services';
import { Router } from '@angular/router';
import { GroupsService } from '../groups.service';
import { StaticContentService } from '../static-content.service';
import { filter, map, takeWhile, tap } from 'rxjs/operators';
import { FIRST_TIME_DEP } from './user.service';
import {UserInfoService} from './user-info.service';

@Injectable({
  providedIn: 'root'
})
export class UserCustomFlowService {

  constructor(
    private _cookie: CookieService,
    private _router: Router,
    private _groups: GroupsService,
    private _static: StaticContentService,
    private _userInfo: UserInfoService
  ) {
  }

  /**
   * Handle Custom Redirect For Stags
   * @public
   */
  public customStagOnDepositRedirect() {
    if (!this._groups.isExistGroup('ID378')) {
      this._static.item( {slug: 'custom-redirect'} ).pipe(
        takeWhile( data => !data, true ),
        filter( (data) => !!data ),
        map( (data) => data[0]?.StagsAndGames?.list[this._cookie.get( 'id_id' )] ),
        tap( (data) => {
          if (!!data) {
            this._router.navigateByUrl( `/play${ data }` );
          }
        } ),
      ).subscribe()
    }
  }

  /**
   * Retrieves the full register version information.
   * @returns An observable that emits the full register version information.
   */
  public getRegisterVersion() {
    return this._static.item({ slug: 'full-register-stags' }).pipe(
      filter(list => !!list),
      tap((list) => list.length ? this._userInfo.isFullRegister$.next(true) : this._userInfo.isFullRegister$.next(false))
    );
  }
}
